import React, { useState } from "react";
import "./Header.css";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavClick = () => {
    console.log("df")
    setIsNavOpen(!isNavOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsNavOpen(false); // Close the nav menu after click
  };

  return (
    <header className="header">
      <img src="/Logo.png" alt="CZ4PREZ" className="logo" />
      <nav className={`nav ${isNavOpen ? "active" : ""}`} onClick={handleNavClick}>
        <div className="nav-item" style={{ cursor: "pointer" }}><a className="nav-item" href=" https://dappbay.bnbchain.org/detail/cz-runz" target="_blank">Dappbay</a></div>
        <div className="nav-item" style={{ cursor: "pointer" }}><a className="nav-item" href="https://dappradar.com/dapp/cz-runz" target="_blank">DappRadar</a></div>
        <div className="nav-item" style={{ cursor: "pointer" }}><a className="nav-item" href="https://t.me/cz_runzbot/czrunz" target="_blank">PLAY NOW</a></div>
        <div className="nav-item" style={{ cursor: "pointer" }} onClick={() => scrollToSection("tokenomics")}>TOKENOMICS</div>
        {/* <a
          href="https://t.me/playdeckbot/game?startapp=eyJnYW1lIjp7ImtleSI6InlCT1c3dDVseEEifSwibGFiZWxzIjpbImRldiJdfQ=="
          target="_blank"
          className="nav-item-link"
        >
          <div className="nav-item">
            GAME
          </div>
        </a> */}
        <a href="https://t.me/CZRUNZ" target="_blank"><img src="/Telegram.png" alt="Telegram" className="icon telegram" /></a>
        <a href="https://x.com/CzRunz" target="_blank"><img src="/X.png" alt="X" className="icon twitter" /></a>
        <button className="buy-button">BUY $CZRUNZ</button>
      </nav>
      <div className={`hamburger ${isNavOpen ? "active" : ""}`} onClick={handleNavClick}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </header>
  );
};

export default Header;
